import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const MapWrapper = styled.div`
	width: 100%;
	margin-bottom: 4.75rem;
	border-radius: 21px;
`;

export const CardSectionWrapper = styled.div``;

export const TitleWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	> div {
		margin: 0 10% 2rem 0;
	}
`;
