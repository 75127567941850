import React, {useEffect, useState} from 'react';
import {Select, Space} from 'antd';
import {BreadCrumb} from '../../../Components/Template';
import {Container} from '../../../Components/Atom';
import {Pic as PicTemplate} from '../../../Components/Template';
import {checkPassword, getListBoardPic} from '../../../api/board';

const Pic = () => {
	// const [page, setPage] = useState(1);
	const [boards, setBoards] = useState([]);
	useEffect(() => {
		(async () => {
			let now = new Date();
			let res = await getListBoardPic({category: '사진', year: now.getFullYear()});
			setBoards(res.data);
		})();
	}, []);

	const handleChange = (value) => {
		(async () => {
			let res = await getListBoardPic({category: '사진', year: value});
			setBoards(res.data);
		})();
	};

	const goBoard = async ({id, isPassword = false}) => {
		if (isPassword) {
			let password = prompt('비밀번호를 입력해주세요.');
			if (password === null) {
				return;
			}
			let res = await checkPassword({password, id});
			if (!res.isSuccess) {
				return alert('비밀번호가 일치하지 않습니다.');
			}
		}
		window.location.href = window.location.href + `/${id}`;
	};

	return (
		<Container>
			<BreadCrumb depths={['HOME', '참여마당', '사진게시판']} />

			<Space wrap>
				<Select
					defaultValue='2024'
					style={{
						width: 120,
					}}
					onChange={(value) => {
						handleChange(value)
					}}
					options={[
						{
							value: '2025',
							label: '2025',
						},
						{
							value: '2024',
							label: '2024',
						},
						{
							value: '2023',
							label: '2023',
						},
					]}
				/>
			</Space>
			<PicTemplate images={boards} onClick={goBoard} />
		</Container>
	);
};

export default Pic;
