import React from 'react';
import {imagePropsTitle, imageProps, limitTime} from './data';
import * as S from './style';
import {BreadCrumb, CardSection} from '../../../Components/Template';
import {Container, Image} from '../../../Components/Atom';
import {ContentTitle} from '../../../Components/Organism';

const Course = () => {
	return (
		<Container>
			<BreadCrumb depths={['HOME', '대회코스']} />
			<S.Container>
				<S.ImgWrapper>
					<ContentTitle {...imageProps.title0} />
					<div style={{marginTop: '30px'}}></div>
					<S.CardWrapper>
						<Image {...imageProps.course0} />
					</S.CardWrapper>
					<ContentTitle {...imageProps.title} />
					<div style={{marginTop: '30px'}}></div>
					<S.CardWrapper>
						<Image {...imageProps.course} />
					</S.CardWrapper>
					<div style={{marginTop: '50px'}}></div>
					<ContentTitle {...imageProps.title2} />
					<div style={{marginTop: '30px'}}></div>
					<S.CardWrapper>
						<Image {...imageProps.course2} />
					</S.CardWrapper>
					<div style={{marginTop: '50px'}}></div>
					<ContentTitle {...imageProps.title3} />
					<div style={{marginTop: '30px'}}></div>
					<S.CardWrapper>
						<Image {...imageProps.course3} />
					</S.CardWrapper>
					<div style={{marginTop: '50px'}}></div>
					<ContentTitle {...imageProps.title4} />
					<div style={{marginTop: '30px'}}></div>
					<S.CardWrapper>
						<Image {...imageProps.course4} />
					</S.CardWrapper>
				</S.ImgWrapper>
				<S.CardWrapper>
					<CardSection {...limitTime} />
				</S.CardWrapper>
			</S.Container>
		</Container>
	);
};

export default Course;
