import {makeCourse, makeDay, makeMonth, makeYear} from '../../../util';
import {onlyLetter, onlyNumber} from '../../../util/validator';
import {
	inputTextProps,
	inputEnglishTextProps,
	formButtonProps,
	inputButtonProps,
	warnText,
	feeTableProps,
	tabletFeeTableProps,
	ulsContentProps,
	tableLabelText,
	fontSize18,
} from '../../common';
import {fontWeight, defaultSize} from '../../../config';
export const firstProps = {
	inputs: [
		{
			type: 'input',
			name: 'name',
			text: {
				...inputTextProps,
				children: '이름',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Name',
			},
			input: {
				...inputTextProps,
				name: 'name',
				pattern: onlyLetter,
				placeholder: '이름을 입력해주세요.',
			},
			warnText: {
				...warnText,
			},
		},
		{
			type: 'select',
			name: 'birth',
			text: {
				...inputTextProps,
				children: '생년월일',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Date of Birth',
			},
			selects: [
				{
					...inputTextProps,
					name: 'year',
					placeholder: '년도 (Year)',
					options: makeYear(),
				},
				{
					...inputTextProps,
					name: 'month',
					placeholder: '월 (Month)',
					options: makeMonth(),
				},
				{
					...inputTextProps,
					name: 'day',
					placeholder: '일 (Day)',
					options: makeDay(),
				},
			],
			warnText: {
				...warnText,
			},
		},
		{
			type: 'input',
			name: 'password',
			text: {
				...inputTextProps,
				children: '비밀번호',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Password',
			},
			input: {
				...inputTextProps,
				name: 'password',
				placeholder: '비밀번호 숫자 4자리를 입력해 주세요.',
			},
			warnText: {
				...warnText,
			},
		},
	],
	cardContent: {
		uls: [
			{
				listStyle: 'none',
				items: [
					{
						content: {
							...ulsContentProps,
							children:
								'정보조회를 위해 아래의 정보를 입력해 주세요.',
						},
					},
				],
			},
		],
	},
	button: {
		text: {
			...formButtonProps,
			children: '조회하기',
		},
	},
};

export const secondProps = {
	info: [
		[
			{
				title: {
					children: '이름',
					...tableLabelText,
				},
				content: {
					children: '홍길동',
					color: '#464646',
					fontWeight: 500,
					...fontSize18,
				},
				key: 'name',
			},
			{
				title: {children: '입금자명', ...tableLabelText},
				content: {
					children: '홍길동',
					color: '#464646',
					fontWeight: 500,
					...fontSize18,
				},
				key: 'depositor',
			},
			{
				title: {
					children: '생년월일',
					...tableLabelText,
				},
				content: {
					children: '1970년 1월 1일',
					color: '#464646',
					fontWeight: 500,
					...fontSize18,
				},
				key: 'birth',
			},
		],
		[
		{
			title: {children: '주소', ...tableLabelText},
			content: {
				children:
					'전라남도 여수시 덕충 3길 3 (덕충동) 2층 망고소프트',
				color: '#464646',
				fontWeight: 500,
				...fontSize18,
			},
			key: 'address',
		},
		],
		[
			{
				title: {children: '참가종목', ...tableLabelText},
				content: {
					children: '10km 여자부',
					color: '#464646',
					fontWeight: 500,
					...fontSize18,
				},
				key: 'course',
			},
			{
				title: {children: '휴대전화', ...tableLabelText},
				content: {
					children: '010-0000-0000',
					color: '#464646',
					fontWeight: 500,
					...fontSize18,
				},
				key: 'phone',
			},
		],
		[
			{
				title: {children: '이메일', ...tableLabelText},
				content: {
					children: '',
					color: '#464646',
					fontWeight: 500,
					...fontSize18,
				},
				key: 'email',
			},

		],
		// [
		// 	{
		// 		title: {children: '기념품 옵션', ...tableLabelText},
		// 		content: {children: '티셔츠', ...fontSize18},
		// 		key: 'gift',
		// 	},
		// ],
	],
	cardContent: {
		uls: [
			{
				listStyle: 'circle',
				items: [
					{
						content: {
							...ulsContentProps,
							children:
								'정확하고 빠른 입금확인을 위해 참가비 입금시 입금자 이름 옆에 생년월(ex:홍길동 6012)을 기록하여 주시기 바랍니다.',
						},
					},
					{
						content: {
							...ulsContentProps,
							children:
								'참가비 입금이 되어야 접수가 완료되며 참가자명과 입금자명이 다를 경우 필히 대회사무국으로 전화 주시기 바랍니다.',
						},
					},
				],
			},
		],
	},
	fee: feeTableProps,
	tabletFee: tabletFeeTableProps,
	button: {
		text: {
			...formButtonProps,
			children: '수정하기',
		},
	},
};

export const thirdProps = {
	inputs: [
		{
			type: 'input',
			name: 'name',
			text: {
				...inputTextProps,
				children: '이름',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Name',
			},
			input: {
				...inputTextProps,
				name: 'name',
				pattern: onlyLetter,
				placeholder: '이름을 입력해주세요.',
			},
			warnText: {
				...warnText,
			},
		},
		{
			type: 'input',
			name: 'phone',
			text: {
				...inputTextProps,
				children: '연락처',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Contact',
			},
			sep: true,
			inputs: [
				{
					...inputTextProps,
					name: 'phone1',
					pattern: onlyNumber,
					maxlength: 3,
					placeholder: '010',
				},
				{
					...inputTextProps,
					name: 'phone2',
					pattern: onlyNumber,
					maxlength: 4,
					placeholder: '1111',
				},
				{
					...inputTextProps,
					name: 'phone3',
					pattern: onlyNumber,
					maxlength: 4,
					placeholder: '2222',
				},
			],
			warnText: {
				...warnText,
			},
		},
		{
			type: 'select',
			name: 'birth',
			text: {
				...inputTextProps,
				children: '생년월일',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Date of Birth',
			},
			selects: [
				{
					...inputTextProps,
					name: 'year',
					placeholder: '년도 (Year)',
					options: makeYear(),
					fontWeight: fontWeight.base,
				},
				{
					...inputTextProps,
					name: 'month',
					placeholder: '월 (Month)',
					options: makeMonth(),
					fontWeight: fontWeight.base,
				},
				{
					...inputTextProps,
					name: 'day',
					placeholder: '일 (Day)',
					options: makeDay(),
					fontWeight: fontWeight.base,
				},
			],
			warnText: {
				...warnText,
			},
		},
		{
			type: 'radio',
			name: 'gender',
			text: {
				...inputTextProps,
				children: '성별',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Gender',
			},
			items: [
				{
					value: '남성',
					children: '남성',
				},
				{
					value: '여성',
					children: '여성',
				},
			],
			warnText: {
				...warnText,
			},
		},
		{
			type: 'input',
			name: 'post_number',
			text: {
				...inputTextProps,
				children: '주소',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Address',
			},
			input: {
				...inputTextProps,
				name: 'post_number',
				pattern: onlyNumber,
				placeholder: '59384',
			},
			button: {
				isRounded: true,
				text: {
					...inputButtonProps,
					children: '우편번호 찾기',
				},
			},
			warnText: {
				...warnText,
			},
		},
		{
			type: 'input',
			name: 'address',
			input: {
				...inputTextProps,
				name: 'address',
				placeholder: '주소를 입력해주세요.',
			},
			warnText: {
				...warnText,
			},
		},
		{
			type: 'input',
			name: 'detail_address',
			input: {
				...inputTextProps,
				name: 'detail_address',
				placeholder: '상세 주소를 입력해주세요.',
			},
			warnText: {
				...warnText,
			},
		},
		{
			type: 'email',
			name: 'email',
			text: {
				...inputTextProps,
				children: '이메일',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'E-mail',
			},
			input: {
				name: 'email',
				placeholder: 'test@test.com',
			},
			warnText: {
				...warnText,
			},
		},
		{
			type: 'input',
			name: 'depositor',
			text: {
				...inputTextProps,
				children: '입금자명',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Name of depositor',
			},
			input: {
				...inputTextProps,
				name: 'depositor',
				placeholder: '입금자명을 입력해주세요.',
			},
			warnText: {
				...warnText,
			},
		},
		{
			type: 'radio',
			name: 'course',
			text: {
				...inputTextProps,
				children: '참가종목',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Participating events',
			},
			items: makeCourse(),
			isDisabled: true,
			warnText: {
				...warnText,
			},
		},
		// {
		// 	type: 'select',
		// 	name: 'gift',
		// 	text: {
		// 		...inputTextProps,
		// 		children: '기념품 옵션',
		// 	},
		// 	englishText: {
		// 		...inputEnglishTextProps,
		// 		children: 'Option',
		// 	},
		// 	selects: [
		// 		{
		// 			...inputTextProps,
		// 			name: 'gift',
		// 			placeholder: '기념품 선택',
		// 			options: [
		// 				{value: 1, name: '사이즈 95'},
		// 				{value: 2, name: '사이즈 100'},
		// 				{value: 3, name: '사이즈 105'},
		// 				{value: 4, name: '사이즈 110'},
		// 				{value: 5, name: '사이즈 115'},
		// 			],
		// 		},
		// 	],
		// 	warnText: {
		// 		...warnText,
		// 	},
		// },
	],
	button: {
		text: {
			...formButtonProps,
			children: '수정완료',
		},
	},
};

export const invalidProps = {
	name: '',
	phone: '',
	birth: '',
	gender: '',
	post_number: '',
	address: '',
	detail_address: '',
	email: '',
	depositor: '',
	course: '',
	// gift: '',
};
