import {fontWeight, colorPalette, fontSize} from '../../../config';
import {
	cardTitleProps,
	ulsTitleProps,
	ulsContentProps,
	tdProps,
	thProps,
} from '../../common';

export const summary = {
	title: {
		text: {...cardTitleProps, children: '대회개요'},
	},
	uls: [
		{
			listStyle: 'title',
			items: [
				{
					title: {...ulsTitleProps, children: '대회명'},
					content: {
						...ulsContentProps,
						children: '제 19회 여수마라톤대회',
						fontWeight: fontWeight.extraBold,
					},
				},
				{
					title: {...ulsTitleProps, children: '일시'},
					content: {
						...ulsContentProps,
						children: '2025년 01월 12일 (일)',
					},
				},
				{
					title: {...ulsTitleProps, children: '접수마감'},
					content: {
						...ulsContentProps,
						children: '2024년 12월 27일 (금) 자정까지',
					},
				},
				{
					title: {...ulsTitleProps, children: '장소'},
					content: {
						...ulsContentProps,
						children: '여수진남경기장 주경기장',
					},
				},
				{
					title: {...ulsTitleProps, children: '종목'},
					contents: [
						{
							...ulsContentProps,
							children: '풀코스, 하프코스, 10km, 5km',
						},
						{
							...ulsContentProps,
							children: '각 종목당 선착순 1500명만 받습니다',
							color: colorPalette.warn,
							fontWeight: fontWeight.bold,
						},
					],
				},
				{
					title: {...ulsTitleProps, children: '참가비'},
					content: {
						...ulsContentProps,
						children: `- 풀코스(35,000원)\n- 하프코스(30,000원)\n- 10km코스(20,000원)\n- 5km코스(10,000원)`,
					},
				},
				{
					title: {...ulsTitleProps, children: '참가자격'},
					contents: [
						{
							...ulsContentProps,
							children: '나이제한 없으며, 신체 건강한 남/여',
						},
						{
							...ulsContentProps,
							children: `대한육상경기연맹 등록선수 및 선수등록 말소 후 2년 경과되지 않은 자는 참가할 수 없습니다.`,
							fontWeight: fontWeight.bold,
						},
					],
				},
				{
					title: {...ulsTitleProps, children: '문의'},
					content: {
						...ulsContentProps,
						children: `사무국 연락처 : 061-921-6600, 팩스 : 061-921-9906\n사무국 운영시간/09:00~18:00(월~금), 점심시간/12:00~13:00`,
					},
				},
				{
					title: {...ulsTitleProps, children: '주최'},
					content: {
						...ulsContentProps,
						children: '여수마라톤대회 추진위원회',
					},
				},
				{
					title: {...ulsTitleProps, children: '주관'},
					content: {
						...ulsContentProps,
						children: '여수신문',
					},
				},
				{
					title: {...ulsTitleProps, children: '후원'},
					content: {
						...ulsContentProps,
						children:
							'여수시, 여수시의회, 여수경찰서, 여수상공회의소, 여수시체육회, 여수산단공장장협의회',
					},
				},
			],
		},
	],
};

export const way = {
	title: {
		text: {...cardTitleProps, children: '신청방법'},
	},
	uls: [
		{
			listStyle: 'title',
			items: [
				{
					title: {...ulsTitleProps, children: '인터넷'},
					content: {
						...ulsContentProps,
						children: '인터넷으로만 접수 www.ysmarathon.co.kr',
						fontWeight: fontWeight.bold,
					},
				},
				{
					title: {...ulsTitleProps, children: '현장접수'},
					content: {
						...ulsContentProps,
						children: `현장접수는 종목별 선착순 30명으로 제한합니다.\n※ 현장접수자는 시상에서 제외됩니다.`,
						color: colorPalette.warn,
						fontWeight: fontWeight.bold,
					},
				},
			],
		},
	],
};

export const feeTextProps = {
	base: {
		fontWeight: fontWeight.medium,
		color: colorPalette.primary,
		fontSize: {desktop: fontSize.xl},
	},
	accent: {
		fontWeight: fontWeight.bold,
		color: colorPalette.primary,
		fontSize: {desktop: fontSize.xl3},
	},
};

export const fee = {
	display: {
		desktop: 'table',
		tablet: 'table',
		mobile: 'none',
	},
	title: {
		text: {...cardTitleProps, children: '참가비 및 입금계좌'},
	},
	table: {
		display: {
			desktop: 'table',
			tablet: 'table',
			mobile: 'none',
		},
		scroll: false,
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '참가비'},
			{...thProps, children: '입금계좌'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스'},
				{...tdProps, children: '35,000'},
				{
					...tdProps,
					childrens: [
						{
							...feeTextProps.base,
							children:
								'입금은행 :  농협 / 예금주: 여수마라톤추진위원회',
						},
						{
							...feeTextProps.accent,
							children: '355-0091-2634-43\n\n',
						},
						{
							...feeTextProps.base,
							children:
								'입금은행 :  새마을 / 예금주: 여수마라톤추진위원회',
						},
						{
							...feeTextProps.accent,
							children: '9002-2057-8051-1',
						},
					],
					rowSpan: '4',
				},
			],
			[
				{...tdProps, children: '하프코스'},
				{...tdProps, children: '30,000'},
			],
			[
				{...tdProps, children: '10km'},
				{...tdProps, children: '20,000'},
			],
			[
				{...tdProps, children: '5km'},
				{...tdProps, children: '10,000'},
			],
		],
	},
};

export const mobileFee = {
	display: {
		desktop: 'none',
		tablet: 'none',
		mobile: 'table',
	},
	title: {
		text: {...cardTitleProps, children: '참가비 및 입금계좌'},
	},
	table: {
		display: {
			desktop: 'none',
			tablet: 'none',
			mobile: 'table',
		},
		scroll: false,
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '참가비'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스'},
				{...tdProps, children: '35,000'},
			],
			[
				{...tdProps, children: '하프코스'},
				{...tdProps, children: '30,000'},
			],
			[
				{...tdProps, children: '10km'},
				{...tdProps, children: '20,000'},
			],
			[
				{...tdProps, children: '5km'},
				{...tdProps, children: '10,000'},
			],
		],
	},
};

export const mobileDeposit = {
	display: {
		desktop: 'none',
		tablet: 'none',
		mobile: 'table',
	},
	title: {
		text: {...cardTitleProps, children: '참가비 및 입금계좌'},
	},
	table: {
		display: {
			desktop: 'none',
			tablet: 'none',
			mobile: 'table',
		},
		scroll: false,
		ths: [{...thProps, children: '입금계좌'}],
		trs: [
			[
				{
					...tdProps,
					childrens: [
						{
							...feeTextProps.base,
							children:
								'입금은행 :  농협 / 예금주:  여수마라톤추진위원회',
						},
						{
							...feeTextProps.accent,
							children: '355-0091-2634-43\n',
						},
						{
							...feeTextProps.base,
							children:
								'입금은행 :  새마을 / 예금주:  여수마라톤추진위원회',
						},
						{
							...feeTextProps.accent,
							children: '9002-2057-8051-1',
						},
					],
				},
			],
		],
	},
};

export const refundPolicy = {
	title: {
		text: {...cardTitleProps, children: '환불규정'},
	},
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'입금 확인은 입금 다음 날부터 가능 (접수 마감일까지 미입금시 자동 접수 취소)',
					},
				},
				{
					content: {
						...ulsContentProps,
						children: `본인 실수로 인해 입금 확인이 되지 않을 경우 참가 불가 (10% 공제 후 환불)`,
					},
				},
				{
					content: {
						...ulsContentProps,
						children: `반드시 신청자 본인 명의 입금 (단체는 입금자명에 단체명 표기해 입금)`,
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'공식 접수마감 이후 취소나 종목 변경 불가 (연장접수기간 취소 및 환불 불가)',
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'접수마감 전 취소시 10% 공제 후 환불 (환불 소요기간은 약 1 주일 소요)',
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'참가자가 본인 이름으로 입금하지 않아, 입금 확인이 되지 않을 경우, 참가할 수 없으며 입금 금액의 10% 를 공제한 후 환불 (환불 소요기간은 약 1 주일 소요)',
					},
				},
			],
		},
		// {
		// 	listStyle: 'title',
		// 	items: [
		// 		{
		// 			title: {
		// 				...ulsTitleProps,
		// 				children: '환불 신청 기간',
		// 				type: 'long',
		// 			},
		// 			content: {
		// 				...ulsContentProps,
		// 				children:
		// 					'참가 접수기간 중에만 가능 (참가 접수 마감 후에는 불가능)',
		// 			},
		// 		},
		// 		{
		// 			title: {
		// 				...ulsTitleProps,
		// 				children: '환불 신청방법',
		// 				type: 'long',
		// 			},
		// 			content: {
		// 				...ulsContentProps,
		// 				children: '홈페이지 자유게시판에 비밀글로 직접 신청',
		// 			},
		// 		},
		// 		{
		// 			title: {
		// 				...ulsTitleProps,
		// 				children: '환불액 지급 방법',
		// 				type: 'long',
		// 			},
		// 			content: {
		// 				...ulsContentProps,
		// 				children:
		// 					'개인 계좌를 통한 환불 처리 (환불 신청 후 7일 ~ 10일 소요)',
		// 			},
		// 		},
		// 		{
		// 			title: {
		// 				...ulsTitleProps,
		// 				children: '환불 금액',
		// 				type: 'long',
		// 			},
		// 			content: {
		// 				...ulsContentProps,
		// 				children:
		// 					'참가비의 10%를 제외한 금액 (사무국 운영, 은행 수수료 등 제반 경비)',
		// 			},
		// 		},
		// 	],
		// },
	],
};

export const schedule = {
	title: {
		text: {...cardTitleProps, children: '대회일정'},
	},
	table: {
		ths: [
			{...thProps, children: '일자'},
			{...thProps, children: '시간'},
			{...thProps, children: '내용'},
		],
		trs: [
			[
				{...tdProps, children: '2025. 01. 12 (일요일)', rowSpan: '17'},
				{...tdProps, children: '09:00'},
				{...tdProps, children: '개회통고'},
			],
			[
				{...tdProps, children: '09:01'},
				{...tdProps, children: '내빈소개'},
			],
			[
				{...tdProps, children: '09:02'},
				{...tdProps, children: '개회선언'},
			],
			[
				{...tdProps, children: '09:03'},
				{...tdProps, children: '국민의례'},
			],
			[
				{...tdProps, children: '09:05'},
				{...tdProps, children: '대회사'},
			],
			[
				{...tdProps, children: '09:08'},
				{...tdProps, children: '환영사'},
			],
			[
				{...tdProps, children: '09:10'},
				{...tdProps, children: '축사'},
			],
			[
				{...tdProps, children: '09:14'},
				{...tdProps, children: '몸풀기, 스트레칭'},
			],
			[
				{...tdProps, children: '09:24'},
				{...tdProps, children: '출발선 이동'},
			],
			[
				{...tdProps, children: '09:30'},
				{...tdProps, children: '풀코스 출발'},
			],
			[
				{...tdProps, children: '09:40'},
				{...tdProps, children: '하프코스 출발'},
			],
			[
				{...tdProps, children: '09:50'},
				{...tdProps, children: '10km 출발'},
			],
			[
				{...tdProps, children: '09:55'},
				{...tdProps, children: '5km 출발'},
			],
			[
				{...tdProps, children: '10:55'},
				{...tdProps, children: '5km 종료'},
			],
			[
				{...tdProps, children: '11:50'},
				{...tdProps, children: '10km 종료'},
			],
			[
				{...tdProps, children: '12:40'},
				{...tdProps, children: '하프코스 종료'},
			],
			[
				{...tdProps, children: '14:30'},
				{...tdProps, children: '풀코스 종료'},
			],
		],
		descriptions: [
			{
				content: {
					children: `위 일정은 행사장 상황에 따라 변동될 수 있습니다.`,
				},
			},
		],
	},
};
